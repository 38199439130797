<template>
<h1>Newcomers</h1>
<hr>
<h2>Welcome to Sexaholics Anonymous!</h2>

<p>We are glad you are here. If you identify with us and think you may share our problem, we would like to share our solution with you. Newcomers like yourself often have a lot of questions about our program. We will attempt to answer some of them.</p>

<h3>What is SA?</h3>

<p>We are a fellowship of men and women who share our experience, strength and hope with each other that we may solve our common problem of sexual addiction and help others to recover. Our primary purpose is to stay sexually sober and help other sexaholics to achieve sexual sobriety.</p>

<h3>What is Sexual Sobriety?</h3>

<p>In defining sobriety, we do not speak for those outside of Sexaholics Anonymous. Sexual sobriety for sexaholics of our type means no sex with ourselves and no sex with any partner other than the spouse. In SA's sobriety definition, the term “spouse” refers to one's partner in a marriage between a man and a woman. Sexual sobriety also means progressive freedom from the many forms of sexual thinking and stimulation and lust that enter our lives. This freedom is found by remaining sober and by using our Twelve Steps and Twelve Traditions in our daily lives. (See the Twelve Steps and Twelve Traditions)</p>

<h3>How can I know if I am a lust addict?</h3>

<p>In SA there are 20 questions which a person can ask themselves. We in SA find we are able to indentify with some, and in some cases all of these questions. <router-link to="/twentyQs" role="button">Click here to view the questions.</router-link></p>

<h3>Is SA Group Therapy?</h3>

<p>SA is not a form of sex therapy or group therapy. SA meetings are conducted by SA members using our meeting guidelines. There are no professional leaders at an SA meeting. SA is a program of recovery from lust and sexual addiction based on the principles of Alcoholics Anonymous. Whatever problems we bring to SA, we share a common solution - the Twelve Steps and Twelve Traditions of recovery practiced in fellowship on the foundation of sexual sobriety.</p>

<h3>How Can I Become A Member?</h3>

<p>All who believe they may have a problem with lust are welcome to attend SA meetings. The only requirement for membership is a desire to stop lusting and become sexually sober.</p>

<h3>We live in the solution!</h3>

<p>We practice a positive sobriety, taking the actions of love to improve our relationships with others. We are learning how to give; and the measure we give, is the measure we get back. We are finding what none of the substitutes had ever supplied. We are making the real Connection. We are home.</p>

<h3>Where is CASA located?</h3>

<p>Capital Area Sexaholics Anonymous (CASA) hosts the SA meetings in Washington, DC and nearby counties in Virginia and Maryland.</p>

<h3>Interested? Contact Us</h3>
<p>Email us at <a href="mailto:ContactCASA12@gmail.com">ContactCASA12@gmail.com</a> or call 703-866-6929</p>
<p>Please leave your first name, phone number and a good time to call!</p>

</template>

<style scoped>
h3{
    text-align: left;
}

p {
text-align: left;
}

</style>